/* font implementation */
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

// color
$bgcolor: #E6EDEF;
$forecolor: #FFFFFF;
$primarycolor: #147350;
$secondarycolor: #E8F1EE;
$txt-darkcolor: #373737;
$txt-lightcolor: #888888;
$border-color: #B2B2B2;
$del-btn-color: #FF7575;
$highlight-color: #FFEEBD;
$asterisk-color: #DB4C4C;
$box-shadow-color: #000000;
$toast-info-color: #0079BC;
$toast-success-color: #3CA85A;
$toast-warning-color: #DD803B;
$toast-danger-color: #E46C6C;

// fontfamily
$primary-font-family: "Inter", sans-serif;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;

// sizes
$size-vxs: 13px;
$size-xs: 16px;
$size-vsm: 18px;
$size-sm: 20px;
$size-md: 21px;
$size-lg: 24px;
$size-xl: 26px;

// opacity
$opacity-tint-low: 0;
$opacity-tint-high: 100;
@use "./Theme.scss" as theme;

.home-container {
  background-color: rgba(230, 237, 239, 1);
  & .centered-display-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    padding-bottom: 80px;
    & .centered-box {
        padding: theme.$size-sm;
        background-color: theme.$forecolor;
        & .app-greet-txt {
            font-weight: theme.$font-weight-regular;
            font-size: theme.$size-vsm;
            line-height: theme.$size-lg;
            text-align: left;
            margin: 0;
        }
        & .app-select-heading {
            font-weight: theme.$font-weight-regular;
            font-size: theme.$size-xs;
            line-height: theme.$size-lg;
            text-align: left;
            margin-bottom: 0;
        }
        & .app-tagline {
            font-weight: theme.$font-weight-regular;
            font-size: theme.$size-vxs;
            line-height: theme.$size-vsm;
            text-align: left;
            margin: 0;
        }
        & .icon-bg {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 25px;
        }
        & .fs-parent-container {
            display: flex;
            width: 50%;
            margin: 15px;
            & a {
                flex: 1;
                text-decoration: none;
                & .supply-bg {
                    background-color: rgba(20, 115, 80, 0.05);
                    width: 300px;
                    height: 120px;
                    border-radius: 12px;
                    padding: 20px;
                    margin: 20px;
                    cursor: pointer;
                    &:hover {
                        background-color: rgba(255, 108, 0, 0.05);
                    }
                    & .app-name {
                        color: rgba(20, 115, 80, 1);
                        font-weight: theme.$font-weight-regular;
                        font-size: theme.$size-xs;
                        line-height: theme.$size-vsm;
                    }
                }
            }
        }
    }
    
  }
}

@use "./Theme.scss" as theme;

.header {
    background-color: #147350;
    padding: 10px 20px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .project-name {
    display: inline-block;
    vertical-align: top;
    padding-top: 15px;
    padding-left: 15px;
    text-transform: capitalize;
    font-family: theme.$primary-font-family;
    font-size: 14px;
    font-weight: 500;
  }
  .project-logo {
    background-color: #fff;
    padding: 5px;
  }
  .user-details {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: #ffffff;
    color: #000000;
    font-weight: 500;
    font-size: 15px;
    line-height: 32px;
  }